<script setup lang="ts">
import { Dropdown as VDropdown } from "floating-vue";
import { UButton, UListItem } from "#components";

type VDropdownProps = InstanceType<typeof VDropdown>["$props"];
type VDropdownTriggerProps = InstanceType<typeof UButton>["$props"];
type UListItemProps = InstanceType<typeof UListItem>["$props"];

export type MenuItem = (UListItemProps & { children?: UListItemProps[] });
export interface MenuState {
  close: () => void;
}

const props = withDefaults(
  defineProps<{
    dropdown?: VDropdownProps;
    items?: MenuItem[];
    trigger?: VDropdownTriggerProps;
  }>(),
  {
    dropdown: () => ({
      placement: "bottom-end",
      distance: 14,
    }),
    trigger: () => ({ icon: "dots", variant: "link-secondary" }),
  },
);
const emit = defineEmits(["show", "hide"]);

const dropdownRef = ref<InstanceType<typeof VDropdown>>();
const menuState = shallowRef<MenuState>({ close });
const isMobile = useIsMobile();
const isShown = shallowRef(false);

function close() {
  if (dropdownRef.value)
    dropdownRef.value.hide();
  else
    console.warn("Popper instance is not available!");
}

provide("ui.menu", menuState);

defineExpose({ isShown });
</script>

<template>
  <VDropdown
    ref="dropdownRef"
    v-model:shown="isShown"
    v-bind="props.dropdown"
    role="menu"
    class="inline-flex"
    :positioning-disabled="isMobile"
    auto-hide
    @show="emit('show')"
    @hide="emit('hide')"
  >
    <template #default="{ show, hide, shown }">
      <slot name="trigger" :shown="shown" :show="show" :hide="hide" :toggle="() => shown ? show : hide">
        <UButton v-bind="props.trigger" @click.stop.prevent="show" />
      </slot>
    </template>

    <template #popper="{ hide }">
      <UTransitionDefault appear>
        <div class="u-menu-content">
          <slot :hide="hide">
            <UList v-if="props.items?.length">
              <template v-for="item in props.items">
                <VDropdown v-if="item.children" :key="`dd-${item.icon}-${item.text}`" placement="left-start" instant-move>
                  <template #default="{ shown }">
                    <UListItem
                      v-bind="item" :trailing-icon="item.children?.length
                        ? { name: 'chevron-right-small', class: 'text-black dark:text-white' }
                        : null" :active="shown" role="menuitem"
                    />
                  </template>

                  <template v-if="item.children" #popper>
                    <UList>
                      <UListItem v-for="childItem in item.children" :key="`${childItem.icon}-${childItem.text}`" v-bind="childItem" role="menuitem" />
                    </UList>
                  </template>
                </VDropdown>
                <UListItem v-else :key="`${item.icon}-${item.text}`" v-bind="item" role="menuitem" />
              </template>
            </UList>
          </slot>
        </div>
      </UTransitionDefault>
    </template>
  </VDropdown>
</template>

<style lang="scss">
.u-menu-content {
  @apply bg-white dark:bg-gray-900
  text-gray-900 dark:text-white
  rounded shadow-[0px_8px_32px_rgba(5,27,68,0.12)] dark:shadow-[0px_11px_10px_0px_rgba(0,0,0,0.3)];
}
</style>
